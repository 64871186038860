.datatable-demo .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td:last-child .p-column-title {
    display: none;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details > .p-grid {
    border-radius: 3px;
    margin: 0.3em;
    text-align: center;
    padding: 2em 0 2.25em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
    font-weight: 700;
    font-size: 20px;
    margin-top: 24px;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
    margin: 0.25em 0 2em 0;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
    margin-left: 0.5em;
}

.carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
    margin-left: 0;
}

.dataview-demo .p-dataview .car-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
}

.dataview-demo .p-dataview .car-details > div {
    display: flex;
    align-items: center;
}

.dataview-demo .p-dataview .car-details > div img {
    margin-right: 14px;
}

.dataview-demo .p-dataview .car-detail {
    padding: 0 1em 1em 1em;
    margin: 1em;
}

@media (max-width: 1024px) {
    .dataview-demo .p-dataview .car-details img {
        width: 75px;
    }
}
/*Own stiles*/
.ta-left {
    text-align: left;
}

.ta-right {
    text-align: right;
}

.ta-center {
    text-align: center;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-40 {
    margin-left: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px;
}
.padd-40 {
    padding: 40px;
}
.padd-left-50 {
    padding-left: 50px;
}

.padd-right-50 {
    padding-right: 50px;
}

.padd-left-60 {
    padding-left: 60px;
}

.padd-right-60 {
    padding-right: 60px;
}

.row.card-image {
    margin: 25px -15px;
}

.row.card-image h4 {
    margin-top: 60px;
}

.card-benefits h2 {
    color: #000;
    margin-bottom: 50px;
}

.card-benefits img {
    margin-bottom: 20px;
}

.p-sm-1, .p-sm-2, .p-sm-3, .p-sm-4, .p-sm-5, .p-sm-6, .p-sm-7, .p-sm-8, .p-sm-9, .p-sm-10, .p-sm-11, .p-sm-12, .p-md-1, .p-md-2, .p-md-3, .p-md-4, .p-md-5, .p-md-6, .p-md-7, .p-md-8, .p-md-9, .p-md-10, .p-md-11, .p-md-12, .p-lg-1, .p-lg-2, .p-lg-3, .p-lg-4, .p-lg-5, .p-lg-6, .p-lg-7, .p-lg-8, .p-lg-9, .p-lg-10, .p-lg-11, .p-lg-12, .p-xl-1, .p-xl-2, .p-xl-3, .p-xl-4, .p-xl-5, .p-xl-6, .p-xl-7, .p-xl-8, .p-xl-9, .p-xl-10, .p-xl-11, .p-xl-12 {
    padding: 0.7em;
}

body {
    background-image: url(./img/bg.png);
}

.banner {
    background-image: url("./img/banner-bg.jpg");
    background-size: cover;
    padding: 130px 0 90px;
}

.banner2 {
    background-image: url("./img/income-from-card.jpg");
    background-size: cover;
    padding: 90px 0 0px;
}

.reduce-cost-with-e-wallet {
    background-image: url("./img/reduce-cost-with-e-wallet.jpg");
    background-size: cover;
    padding: 25px 0 40px;
}

.promioting-business {
    background-image: url("./img/promioting-business.jpg");
    background-size: cover;
    padding: 40px 0 50px;
}

.income-from-card {
    background-image: url("./img/income-from-card.jpg");
    background-size: cover;
    padding: 30px 0;
}
.card-benefits {
    background-image: url("./img/right-bevel.png");
    background-position: calc(100% + 300px) bottom;
    background-repeat: no-repeat;
    padding: 50px 0 70px;
    text-align: center;
}
